
html {scroll-behavior: smooth;}

.AppWrap {
  margin:1rem 1rem 2rem;
  font-size:16px;
  -webkit-text-size-adjust: 100%;
}

.App {
  width:100%;
  max-width:400px;
  margin:0 auto;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin:0 0 1.5rem;
}

.logo {margin-top:1.5rem;}

.App-link {
  color: #61dafb;
}

.languagePicker {margin-left:-.5rem;}

.btn-link:active, .btn-link:focus {outline:0 none;text-decoration:none;box-shadow:none;}

.area-light, .area-lighter, .area-yellow {
  background:#F0F0F3;
  border-radius:.5rem;
  padding:1rem;
}
.area-lighter {
  background: rgba(245, 245, 245, 0.6);
}
.area-yellow {
  background: rgba(254, 199, 0, 0.3);
}
.poweredBy img {max-width:45%;}
.certifiedBy img {max-width:30%;}

.app-download {margin:2rem -1rem;}
.app-download > a {margin:0 1rem;transition:all .5s;}
.app-download > a > img {max-width:100%;}
.app-download > a:hover {opacity:.8;}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}